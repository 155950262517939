import { css, html } from "lit";
import { customElement, query } from "lit/decorators.js";
import { Dialog } from "../dialog";
import { RestoreTimeEntriesForm } from "./restore-time-entries-form";
import { app } from "../../init";
import { alert, confirm } from "../alert-dialog";
import { Employee } from "packages/core/src/model";

@customElement("ptc-restore-time-entries-dialog")
export class RestoreTimeEntriesDialog extends Dialog<{ companyId: number; employees: Employee[] }, void> {
    readonly preventDismiss = true;

    @query("ptc-restore-time-entries-form")
    private _form: RestoreTimeEntriesForm;

    static styles = [
        ...Dialog.styles,
        css`
            .inner {
                padding: 1em;
            }

            .actions > :not(:last-child) {
                margin-right: 0.7em;
            }
        `,
    ];

    renderContent() {
        return html`
            <h3>Arbeitszeiten wiederherstellen</h3>

            <ptc-restore-time-entries-form
                .companyId=${this.input.companyId}
                .employees=${this.input.employees.sort((a, b) => a.id - b.id)}
                @submit=${this._submit}
                @changed=${() => this.requestUpdate()}
                @cancel=${() => this.done()}
            ></ptc-restore-time-entries-form>

            <div class="horizontal layout actions">
                <button class="primary stretch" @click=${this._submit} ?disabled=${!this._form?.data}>
                    Wiederherstellen
                </button>
                <button class="transparent stretch" @click=${this.done}>Abbrechen</button>
            </div>
        `;
    }

    private async _submit() {
        if (!this._data) {
            return;
        }

        if (
            !(await confirm("Willst du diese Einträge wiederherstellen?", "Wiederherstellen", "Abbrechen", {
                title: "Einträge wiederherstellen",
                type: "question",
            }))
        ) {
            return;
        }

        this.loading = true;
        try {
            await app.api.restoreDeletedTimeEntries({ ...this._data });
            this._form.reset();
            await alert("Die Einträge wurden erfolgreich wiederhergestellt.", { type: "success" });
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this.show(this.input);
        }

        this.loading = false;
        this.done();
    }

    private get _data() {
        if (!this._form?.data) {
            return null;
        }

        const { employeeId, from, to } = this._form.data;

        if (!employeeId || !from || !to) {
            return null;
        }

        return { employeeId, from, to };
    }
}

declare global {
    interface HTMLElementTagNameMap {
        "ptc-restore-time-entries-dialog": RestoreTimeEntriesDialog;
    }
}
