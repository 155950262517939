import { html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import { StartSetupPaymentParams, UpdateCompanyParams, UpdateBillingParams } from "@pentacode/core/src/api";
import { Company } from "@pentacode/core/src/model";
import { loadScript } from "../lib/util";
import { shared } from "../styles";
import { app } from "../init";
import { Dialog } from "./dialog";
import { alert } from "./alert-dialog";

@customElement("ptc-billing-dialog")
export class BillingDialog extends Dialog<Company, boolean> {
    @property()
    private _validationError = "";

    @query("form")
    private _form: HTMLFormElement;

    @query("input[name='email']")
    private _emailInput: HTMLInputElement;

    @query("input[name='name']")
    private _nameInput: HTMLInputElement;

    private _company: Company;

    private _stripe: any;

    private _ibanElement: any;
    private _cardElement?: HTMLDivElement;
    private _stripeSDKLoaded: Promise<void>;

    constructor() {
        super();
        this._stripeSDKLoaded = this._loadStripeSDK();
    }

    async show(company: Company) {
        this._company = company;
        const promise = super.show();

        await this.updateComplete;

        this._emailInput.value = company.email || (company.owner && company.owner.email) || "";
        this._nameInput.value = company.name;

        await this._stripeSDKLoaded;
        await this._createIbanElement(company.country);

        return promise;
    }

    private async _loadStripeSDK() {
        const stripePubKey = process.env.PTC_BILLING_PUBLIC_KEY;

        if (!stripePubKey) {
            return;
        }

        const Stripe = await loadScript("https://js.stripe.com/v3/", "Stripe");

        this._stripe = Stripe(stripePubKey);
    }

    private async _createIbanElement(country: "DE" | "AT") {
        if (this._cardElement) {
            this.removeChild(this._cardElement);
        }

        const elements = this._stripe.elements({
            // fonts: [
            //     {
            //         src: `local("Nunito Regular"), local("Nunito-Regular"), url(${Nunito}) format("truetype")`,
            //         family: "Nunito",
            //         style: "normal",
            //         weight: 400
            //     }
            // ]
        });

        const options = {
            supportedCountries: ["SEPA"],
            // Elements can use a placeholder as an example IBAN that reflects
            // the IBAN format of your customer's country. If you know your
            // customer's country, we recommend passing it to the Element as the
            // placeholderCountry.
            placeholderCountry: country,
        };

        // Create an instance of the IBAN Element
        this._ibanElement = elements.create("iban", options);

        this._cardElement = document.createElement("div");
        this.appendChild(this._cardElement);
        this._ibanElement.mount(this._cardElement);

        this._ibanElement.addEventListener("change", (e: any) => {
            this._validationError = (e.error && e.error.message) || "";
        });

        return new Promise((resolve) => this._ibanElement.addEventListener("ready", resolve));
    }

    private async _submit(e: FocusEvent) {
        e.preventDefault();

        this.loading = true;

        try {
            const { secret: clientSecret } = await app.api.startSetupPayment(new StartSetupPaymentParams());

            const data = new FormData(this._form);

            const result = await this._stripe.confirmSepaDebitSetup(clientSecret, {
                payment_method: {
                    sepa_debit: this._ibanElement,
                    billing_details: {
                        name: data.get("name"),
                        email: data.get("email"),
                    },
                },
            });

            if (result.error) {
                throw result.error;
            }

            await app.api.updateCompany(
                new UpdateCompanyParams({
                    id: this._company.id,
                    billing: new UpdateBillingParams({
                        addPaymentMethod: result.setupIntent.payment_method,
                    }),
                })
            );

            await app.fetchAccount();
        } catch (e) {
            this.hide();
            await alert(e.message, { type: "warning" });
            this._show();
        }

        this.loading = false;

        this.done(true);
    }

    static styles = [
        shared,
        ...Dialog.styles,
        css`
            h3 {
                text-align: center;
                margin: 0;
            }

            .inner {
                padding: 1em;
            }

            .stripe-element {
                border: solid 1px var(--shade-2);
                padding: 0.7em;
                border-radius: 4px;
            }

            .actions > :not(:last-child) {
                margin-right: 0.7em;
            }
        `,
    ];

    renderContent() {
        return html`
            <form @submit=${this._submit}>
                <h3>Zahlungsmittel Einrichten</h3>

                <div class="horizontal layout fields">
                    <div class="field stretch">
                        <label>Kontoinhaber</label>
                        <input name="name" placeholder="Kontoinhaber" required />
                    </div>

                    <div class="field stretch">
                        <label>Emailadresse</label>
                        <input name="email" type="email" placeholder="Emailadresse" required />
                    </div>
                </div>

                <div class="field">
                    <label>IBAN</label>

                    <div class="stripe-element">
                        <slot></slot>
                    </div>

                    <div id="error message" ?hidden=${!this._validationError}>${this._validationError}</div>
                </div>

                <div class="horizontal layout actions">
                    <button class="primary stretch" id="submit-button">Abschicken</button>

                    <button class="stretch" type="button" @click=${() => this.done(false)}>Abbrechen</button>
                </div>
            </form>
        `;
    }
}
