import "./popover";
import { LitElement, html, css } from "lit";
import { customElement, property, query } from "lit/decorators.js";
import autosize from "autosize";
import { insertAtCursor } from "../lib/util";
import { EmojiClickEvent } from "./emoji-picker";
import "./emoji-picker";

@customElement("ptc-textarea")
export class Textarea extends LitElement {
    @query("textarea")
    private _textarea: HTMLTextAreaElement;

    async connectedCallback() {
        super.connectedCallback();
        await this.updateComplete;
        autosize(this._textarea);
    }

    @property()
    get value() {
        return this._textarea.value;
    }
    set value(val: string) {
        (async () => {
            if (!this._textarea) {
                await this.updateComplete;
            }

            this._textarea.value = val || "";
            autosize.update(this._textarea);
        })();
    }

    @property()
    name: string;

    @property()
    placeholder: string = "";

    @property({ type: Boolean })
    disableEmojis: boolean = false;

    @property({ type: Number })
    maxLength?: number;

    @query("#lengthIndicator")
    private _lengthIndicator: HTMLSpanElement;

    async focus() {
        if (!this._textarea) {
            await this.updateComplete;
        }
        this._textarea?.focus();
    }

    blur() {
        this._textarea.blur();
    }

    setCustomValidity(val: string) {
        this._textarea.setCustomValidity(val);
    }

    createRenderRoot() {
        return this;
    }

    private _emojiSelected(e: EmojiClickEvent) {
        if (e.detail.unicode) {
            insertAtCursor(this._textarea, e.detail.unicode);
            this._textarea.dispatchEvent(new Event("input", { bubbles: true, composed: true }));
            this._textarea.dispatchEvent(new Event("change", { bubbles: true, composed: true }));
        }
        this._textarea.focus();
    }

    static styles = css`
        ptc-textarea {
            display: block;
            position: relative;
        }

        ptc-textarea textarea {
            resize: none;
            max-height: 20em;
            display: block;
            width: 100%;
            padding: 0.6em 0.8em 2.5em 0.8em;
            background: var(--color-bg);
            appearance: none;
            -webkit-appearance: none;
        }

        ptc-textarea .emoji-button:not(:hover) {
            opacity: 0.5;
        }

        ptc-textarea .emoji-button:hover {
            box-shadow: none;
            color: var(--blue);
        }
    `;

    render() {
        return html`
            <textarea
                .name=${this.name}
                .placeholder=${this.placeholder}
                maxlength=${this.maxLength || ""}
                @input=${this.maxLength
                    ? () => (this._lengthIndicator.innerText = this._textarea.value.length.toString())
                    : null}
            ></textarea>
            <div class="horizontal center-aligning layout absolute bottom right">
                ${!this.disableEmojis
                    ? html`
                          <button type="button" class="larger emoji-button transparent icon">
                              <i class="smile-wink"></i>
                          </button>
                          <ptc-emoji-picker
                              .preferAlignment=${["bottom", "bottom-right", "bottom-left"]}
                              @emoji-click=${this._emojiSelected}
                          >
                          </ptc-emoji-picker>
                      `
                    : ""}
                ${this.maxLength
                    ? html`
                          <div class="subtle small padded">
                              <span id="lengthIndicator">${this._textarea?.value.length || 0}</span> / ${this.maxLength}
                          </div>
                      `
                    : ""}
            </div>
        `;
    }
}
