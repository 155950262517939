import "./company";
import "./../avatar";
import { LitElement, html, css } from "lit";
import { customElement, property, state, query } from "lit/decorators.js";
import { CompanyListing, companyStatusColor, companyStatusLabel } from "@pentacode/core/src/model";
import { comparePropsFn } from "@pentacode/core/src/util";
import { CompanyExport } from "@pentacode/core/src/api";
import { app } from "../../init";
import { config, shared, mixins } from "../../styles";
import { StateMixin } from "../../mixins/state";
import { routeProperty, Routing } from "../../mixins/routing";
import { ServiceWorker } from "../../mixins/service-worker";
import { ErrorHandling } from "../../mixins/error-handling";
import { confirm, alert } from "../alert-dialog";
import Logo from "../../img/logo.svg";
import "../spinner";
import "../scroller";
import { Checkbox } from "../checkbox";
import { DateInput } from "../date-input";
import "../virtual-list";
import { readFileAsText, selectFile } from "../../lib/util";
import { deserialize } from "@pentacode/core/src/encoding";
import "./new-company-dialog";
import { singleton } from "../../lib/singleton";
import { NewCompanyDialog } from "./new-company-dialog";

@customElement("ptc-admin-app")
export class AdminApp extends ServiceWorker(ErrorHandling(StateMixin(Routing(LitElement)))) {
    routeProperty = /.*/;

    readonly active = true;

    @routeProperty({ param: "company", type: Number })
    private _selectedCompanyId: number;

    @property({ type: Boolean, reflect: true, attribute: "singleton-container" })
    readonly singletonContainer = true;

    @state()
    private _loading = false;

    @state()
    private _loginError = "";

    @state()
    private _companies: CompanyListing[] = [];

    @state()
    private _filterString = "";

    @singleton("ptc-new-company-dialog")
    private _newCompanyDialog: NewCompanyDialog;

    @query("#filterInput")
    private _filterInput: HTMLInputElement;

    private _updateFilter() {
        this._filterString = this._filterInput.value.toLowerCase();
    }

    private _clearFilter() {
        this._filterString = this._filterInput.value = "";
    }

    constructor() {
        super();
        // router.addEventListener("route-changed", () => this._routeChanged());
        app.loaded.then(() => this._load());
    }

    private async _login(e: FocusEvent) {
        e.preventDefault();

        const formData = new FormData(e.target as HTMLFormElement);

        this._loading = true;
        this._loginError = "";

        try {
            await app.login({
                email: formData.get("email") as string,
                password: formData.get("password") as string,
                scope: "admin",
            });
            await this.updateComplete;
            this._loading = false;
            this._load();
        } catch (e) {
            this._loading = false;
            this._loginError = e.message || "Anmeldung fehlgeschlagen. Bitte versuchen Sie es sp\u00e4ter noch einmal!";
        }
    }

    private async _load() {
        if (!app.account) {
            return;
        }
        this._loading = true;

        try {
            this._companies = (await app.api.listCompanies()).sort(comparePropsFn("name"));
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }

        this._loading = false;

        this.routeChanged(this.router.path, this.router.params);
    }

    private async _logout() {
        const confirmed = await confirm("Sind Sie sicher dass Sie sich ausloggen möchten?", "Ausloggen");
        if (confirmed) {
            await app.logout();
            window.location.reload();
        }
    }

    private async _createCompany() {
        const company = await this._newCompanyDialog.show();

        if (company) {
            await this._load();
            this.go(null, { company: company.id });
        }
    }

    private async _importCompany() {
        const file = await selectFile();
        if (!file) {
            return;
        }
        const data = await readFileAsText(file);
        const exp = deserialize(CompanyExport, JSON.parse(data));
        this._loading = true;
        try {
            const res = await app.api.importCompany(exp);
            await this._load();
            this.go(null, { company: res.companyId });
        } catch (e) {
            await alert(e.message, { type: "warning" });
        }
        this._loading = false;
    }

    static styles = [
        config,
        shared,
        Checkbox.styles,
        DateInput.styles,
        css`
            :host {
                display: block;
                ${mixins.fullbleed()};
                background: var(--color-bg);
            }

            .login {
                ${mixins.fullbleed()};
                ${mixins.scroll()};
                z-index: 100;
                background: linear-gradient(#444, #111);
                color: var(--color-bg);
                padding: 2em;
            }

            .login .container {
                min-height: 100%;
                text-align: center;
            }

            .login h1 {
                margin: 0.5em;
            }

            .card {
                background: var(--color-bg);
                border-radius: calc(2 * var(--border-radius));
                box-shadow: rgba(0, 0, 0, 0.5) 0 0 10px -5px;
                position: relative;
                overflow: hidden;
            }

            .login .card {
                width: 300px;
                padding: 1em;
                color: var(--color-fg);
                margin: 2em;
            }

            .login .logo {
                height: 50px;
            }

            .login .greeting {
                margin-top: 1em;
                width: 350px;
                text-align: center;
            }

            .login input {
                text-align: inherit;
            }

            .login .message {
                margin: 0 0 0.8em 0;
            }

            .company-item.active {
                font-weight: bold;
                color: var(--blue);
                background: var(--blue-bg);
            }
        `,
    ];

    private _renderLogin() {
        return html`
            <div class="login">
                <div class="container vertical center-aligning center-justifying layout">
                    <img src="${Logo}" class="logo" />

                    <h1>Admin</h1>

                    <div class="greeting">Willkommen zum Pentacode Admin Interface! Bitte melden Sie sich an.</div>

                    <div class="card">
                        <form class="vertical layout" @submit=${this._login} ?disabled=${this._loading}>
                            <div class="field">
                                <input type="email" name="email" placeholder="E-mail Adresse" />
                            </div>

                            <div class="field">
                                <input type="password" name="password" placeholder="Passwort" />
                            </div>

                            <div class="negative message" ?hidden=${!this._loginError}>${this._loginError}</div>

                            <button type="submit" class="primary">Anmelden</button>
                        </form>
                    </div>
                </div>
            </div>
        `;
    }

    private _renderMain() {
        return html`
            <div class="fullbleed horizontal layout stretch collapse">
                <div class="vertical layout border-right">
                    <div class="padded center-aligning horizontal layout">
                        <div class="filter-input right icon input stretch right-margined">
                            <input id="filterInput" type="text" placeholder="Suchen..." @input=${this._updateFilter} />
                            <i
                                class="${this._filterString ? "times click" : "search"} icon"
                                @click=${this._clearFilter}
                            ></i>
                        </div>
                        <button class="slim transparent" @click=${this._importCompany}>
                            <i class="file-import"></i>
                        </button>
                        <button class="slim transparent" @click=${this._createCompany}><i class="plus"></i></button>
                        <button class="slim transparent" @click=${this._logout}><i class="door-open"></i></button>
                    </div>

                    <ptc-virtual-list
                        class="stretch relative"
                        .data=${this._companies.filter(
                            (c) =>
                                c.name.toLowerCase().includes(this._filterString) ||
                                c.id.toString().includes(this._filterString) ||
                                c.venues.some((v) => v.name.toLowerCase().includes(this._filterString))
                        )}
                        .renderItem=${(c: CompanyListing) => html`
                            <div
                                class="click ${this._selectedCompanyId === c.id
                                    ? "active"
                                    : ""} padded border-bottom company-item"
                                @click=${() => this.go(null, { company: c.id })}
                            >
                                <div class="horizontal spacing center-aligning layout bottom-margined">
                                    <div class="stretch collapse ellipsis">
                                        ${c.name} <span class="subtle"> (${c.id})</span>
                                    </div>
                                    <div
                                        class="tiny pill"
                                        style="--color-highlight: ${companyStatusColor(c.effectiveStatus)}"
                                    >
                                        ${companyStatusLabel(c.effectiveStatus)}
                                    </div>
                                </div>
                                <div class="tiny subtle horizontal spacing layout">
                                    ${c.venues.length
                                        ? c.venues.map(
                                              (v) => html`
                                                  <div class="pill ellipsis" style="max-width: 10em">${v.name}</div>
                                              `
                                          )
                                        : html`<div class="pill">[ Ohne Standorte ]</div>`}
                                </div>
                            </div>
                        `}
                    >
                    </ptc-virtual-list>
                </div>

                <div class="stretch relative">
                    <ptc-admin-company
                        class="fullbleed"
                        .companyId=${this._selectedCompanyId}
                        @updated=${this._load}
                    ></ptc-admin-company>
                </div>
            </div>
        `;
    }

    render() {
        return html`
            ${!app.account ? this._renderLogin() : this._renderMain()}

            <div class="fullbleed center-aligning center-justifying vertical layout scrim" ?hidden=${!this._loading}>
                <ptc-spinner ?active=${this._loading}></ptc-spinner>
            </div>

            <ptc-new-company-dialog></ptc-new-company-dialog>

            <slot></slot>
        `;
    }
}
